<template>
    <svg viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M29.75 9.91658V24.0833C29.75 28.3333 27.625 31.1666 22.6667 31.1666H11.3333C6.375 31.1666 4.25 28.3333 4.25 24.0833V9.91658C4.25 5.66659 6.375 2.83325 11.3333 2.83325H22.6667C27.625 2.83325 29.75 5.66659 29.75 9.91658Z"
            :fill="`url(#${gradientId})`"
        />

        <path
            d="M26.2082 13.1042H23.3748C21.2215 13.1042 19.479 11.3617 19.479 9.20833V6.375C19.479 5.79417 19.9607 5.3125 20.5415 5.3125C21.1223 5.3125 21.604 5.79417 21.604 6.375V9.20833C21.604 10.1858 22.3973 10.9792 23.3748 10.9792H26.2082C26.789 10.9792 27.2707 11.4608 27.2707 12.0417C27.2707 12.6225 26.789 13.1042 26.2082 13.1042Z"
            fill="#19202f"
        />

        <path
            d="M14.1667 25.1458C13.8975 25.1458 13.6284 25.0467 13.4159 24.8342L10.5825 22.0009C10.1717 21.59 10.1717 20.91 10.5825 20.4991L13.4159 17.6658C13.8267 17.255 14.5067 17.255 14.9175 17.6658C15.3284 18.0766 15.3284 18.7567 14.9175 19.1675L12.835 21.25L14.9175 23.3325C15.3284 23.7433 15.3284 24.4234 14.9175 24.8342C14.705 25.0467 14.4359 25.1458 14.1667 25.1458Z"
            fill="#19202f"
        />

        <path
            d="M19.8334 25.1458C19.5642 25.1458 19.295 25.0467 19.0825 24.8342C18.6717 24.4234 18.6717 23.7433 19.0825 23.3325L21.165 21.25L19.0825 19.1675C18.6717 18.7567 18.6717 18.0766 19.0825 17.6658C19.4934 17.255 20.1734 17.255 20.5842 17.6658L23.4175 20.4991C23.8284 20.91 23.8284 21.59 23.4175 22.0009L20.5842 24.8342C20.3717 25.0467 20.1025 25.1458 19.8334 25.1458Z"
            fill="#19202f"
        />

        <defs>
            <linearGradient :id="gradientId" x1="17" y1="2.83325" x2="17" y2="31.1666" gradientUnits="userSpaceOnUse">
                <stop stop-color="#27334f" />
                <stop offset="1" stop-color="#6d7c9e" />
            </linearGradient>
        </defs>
    </svg>
</template>

<script>
    import navIconMixin from '@/mixins/navIconMixin';

    export default {
        name: 'ApiIcon',
        mixins: [navIconMixin],
    };
</script>
