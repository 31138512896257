<template>
    <svg viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M30.1042 8.17434V12.7502C30.1042 15.9943 29.07 17.0285 25.8258 17.0285H25.6842V14.7052C25.6842 10.2852 23.715 8.31599 19.295 8.31599H16.9717V8.17434C16.9717 4.93017 18.0058 3.896 21.25 3.896H25.8258C29.07 3.896 30.1042 4.93017 30.1042 8.17434Z"
            :fill="`url(#${gradientId}_1)`"
        />

        <path
            d="M23.5593 14.7051V19.2951C23.5593 22.5251 22.5251 23.5593 19.2951 23.5593H14.7051C11.4751 23.5593 10.4409 22.5251 10.4409 19.2951V14.7051C10.4409 11.4751 11.4751 10.4409 14.7051 10.4409H19.2951C22.5251 10.4409 23.5593 11.4751 23.5593 14.7051Z"
            :fill="`url(#${gradientId}_2)`"
        />

        <path
            d="M17.0285 25.6842V25.8258C17.0285 29.07 15.9943 30.1042 12.7502 30.1042H8.17433C4.93016 30.1042 3.896 29.07 3.896 25.8258V21.25C3.896 18.0059 4.93016 16.9717 8.17433 16.9717H8.31599V19.295C8.31599 23.715 10.2852 25.6842 14.7052 25.6842H17.0285Z"
            :fill="`url(#${gradientId}_1)`"
        />

        <defs>
            <linearGradient :id="`${gradientId}_1`" x1="17.0001" y1="3.896" x2="17.0001" y2="30.1042" gradientUnits="userSpaceOnUse">
                <stop stop-color="#27334f" />
                <stop offset="1" stop-color="#6d7c9e" />
            </linearGradient>

            <linearGradient :id="`${gradientId}_2`" x1="17.0001" y1="3.896" x2="17.0001" y2="30.1042" gradientUnits="userSpaceOnUse">
                <stop stop-color="#27334f" />
                <stop offset="1" stop-color="#6d7c9e" />
            </linearGradient>
        </defs>
    </svg>
</template>

<script>
    import navIconMixin from '@/mixins/navIconMixin';

    export default {
        name: 'ProjectsIcon',
        mixins: [navIconMixin],
    };
</script>
