<template>
    <router-link :to="{ name: 'home' }" class="logo-link">
        <logo />
    </router-link>
</template>

<script>
    import Logo from '@/components/Logo';

    export default {
        name: 'LogoLink',
        components: {
            Logo,
        },
    };
</script>

<style scoped lang="scss">
    .logo-link {
        flex-shrink: 0;
        display: inline-block;
        transition: opacity 0.3s;

        &:hover {
            opacity: 0.75;
        }
    }
</style>
