<template>
    <div class="sidebar">
        <logo-link class="sidebar__logo" />

        <scroll-container type="horizontal">
            <nav class="sidebar__nav">
                <router-link
                    v-for="(item, index) in navItems"
                    :to="{ name: item.to }"
                    :key="index"
                    :class="{ 'sidebar__nav-item': true, active: isNavItemActive(item) }"
                >
                    <component :is="item.icon" :active="isNavItemActive(item)" class="sidebar__nav-icon" />

                    <span class="sidebar__nav-title">{{ item.title }}</span>
                </router-link>
            </nav>
        </scroll-container>

        <div class="sidebar__controls">
            <locale-select class="sidebar__locale-select" :caret="false" :labels="false" position="up" minimal />

            <button type="button" class="sidebar__logout" @click="logout">
                <logout-icon />
            </button>
        </div>
    </div>
</template>

<script>
    import { REMOVE_TOKEN } from '@/store/mutations';
    import AccountIcon from '@/components/icons/AccountIcon';
    import ApiIcon from '@/components/icons/ApiIcon';
    import FaqIcon from '@/components/icons/FaqIcon';
    import LocaleSelect from '@/components/inputs/LocaleSelect';
    import LogoLink from '@/components/LogoLink';
    import LogoutIcon from '@/components/icons/LogoutIcon';
    import ProjectsIcon from '@/components/icons/ProjectsIcon';
    import ScrollContainer from '@/components/ScrollContainer';

    export default {
        name: 'AppSidebar',
        components: {
            AccountIcon,
            ApiIcon,
            FaqIcon,
            LocaleSelect,
            LogoLink,
            LogoutIcon,
            ProjectsIcon,
            ScrollContainer,
        },
        computed: {
            navItems() {
                return [
                    {
                        icon: 'account-icon',
                        title: this.$i18n.t('nav.account'),
                        to: 'account',
                    },
                    {
                        icon: 'projects-icon',
                        title: this.$i18n.t('nav.projects'),
                        to: 'projects',
                    },
                    {
                        icon: 'faq-icon',
                        title: this.$i18n.t('nav.faq'),
                        to: 'faq',
                    },
                    {
                        icon: 'api-icon',
                        title: this.$i18n.t('nav.api'),
                        to: 'docs',
                    },
                ];
            },
        },
        methods: {
            isNavItemActive(item) {
                return item.to === this.$route.name;
            },
            logout() {
                this.$store.commit(`auth/${REMOVE_TOKEN}`);
            },
        },
        data() {
            return {
                localesOpened: false,
            };
        },
    };
</script>

<style lang="scss" scoped>
    .sidebar {
        position: relative;
        display: flex;
        flex-shrink: 0;
        align-items: center;
        justify-content: space-between;
        height: 7.2rem;
        border-radius: 1rem 1rem 0 0;
        background-color: #0f141e;
        z-index: 3;
    }

    .sidebar__logo {
        display: none;
    }

    .sidebar__nav {
        display: flex;
        align-items: center;
        height: 100%;
    }

    .sidebar__nav-item {
        display: flex;
        flex-direction: column;
        flex-shrink: 0;
        align-items: center;
        justify-content: center;
        width: 7.2rem;
        height: 100%;
        color: #5f728d;
        transition: background-color 0.3s;

        &:hover,
        &.active {
            color: #b7c5e3;

            .sidebar__nav-icon::v-deep stop {
                &:first-child {
                    stop-color: #94a3c6;
                }

                &:last-child {
                    stop-color: #7a87a7;
                }
            }
        }

        &:hover {
            background-color: #141926;
        }

        &.active {
            pointer-events: none;
        }

        &:not(:nth-of-type(2)):hover,
        &:not(:nth-of-type(2)).active {
            .sidebar__nav-icon::v-deep path:not(:first-child) {
                fill: #d7e3fd;
            }
        }

        &:nth-of-type(3):hover,
        &:nth-of-type(3).active {
            .sidebar__nav-icon::v-deep path:nth-of-type(2) {
                fill: #c1cfed;
            }
        }

        &:nth-of-type(2):hover,
        &:nth-of-type(2).active {
            .sidebar__nav-icon::v-deep linearGradient:last-of-type stop {
                stop-color: #c1cfed;
            }
        }
    }

    .sidebar__nav-icon {
        width: 2.6rem;
        height: 2.6rem;
        margin-bottom: 0.4rem;

        ::v-deep {
            path {
                transition: fill 0.3s;
            }

            stop {
                transition: stop-color 0.3s;
            }
        }
    }

    .sidebar__nav-title {
        color: inherit;
        font-size: 1rem;
        font-weight: 500;
        text-transform: uppercase;
        transition: color 0.3s;
    }

    .sidebar__controls {
        display: flex;
        align-items: center;
        height: 100%;
        padding: 0 1.5rem;
    }

    .sidebar__locale-select {
        margin-right: 1rem;

        ::v-deep {
            .select__value {
                justify-content: center;
                width: 4rem;
                height: 4rem;
                padding: 0;
                background-color: #171f32;
                transition: opacity 0.3s;

                img {
                    width: 2rem;
                }
            }

            .select__options {
                margin-bottom: -0.5rem;
                padding: 0.5rem 0 0;
                background-color: #171f32;
            }

            .select__option {
                justify-content: center;
                height: 3rem;
                padding: 0;
                border-radius: 0;
                transition: background-color 0.3s;

                &:hover {
                    background-color: #1b253c;
                }

                img {
                    width: 2rem;
                }
            }
        }
    }

    .sidebar__logout {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 4rem;
        height: 4rem;
        border: 1px solid #171f32;
        border-radius: 0.8rem;
        cursor: pointer;
        transition: border-color 0.3s;

        svg {
            width: 2.2rem;
            margin-left: -0.3rem;
            fill: #424b62;
            transition: fill 0.3s;
        }

        &:hover {
            border-color: #7a87a7;

            svg {
                fill: #7a87a7;
            }
        }
    }

    ::v-deep {
        .scroll-container__content {
            height: 100%;
        }

        .scroll-container__horizontal-scrollbar {
            z-index: 1;
        }

        .scroll-container:has(.scroll-container__horizontal-scrollbar[style*='display: block']) + .sidebar__controls {
            box-shadow: 0 0 5rem 0 #000000;
            backdrop-filter: blur(0.5rem);
        }
    }

    @media screen and (min-width: 75em) {
        .sidebar {
            flex: 0 0 8rem;
            flex-direction: column;
            height: auto;
            padding: 1.5rem 0;
            border-radius: 0;
        }

        .sidebar__controls {
            flex-direction: column;
            height: auto;
            padding: 0;
        }

        .sidebar__logo {
            display: block;
        }

        .sidebar__nav {
            flex-direction: column;
            height: auto;
        }

        .sidebar__nav-item {
            width: 6.4rem;
            height: 6.4rem;
            border-radius: 0.8rem;

            &:not(:last-child) {
                margin-bottom: 1rem;
            }
        }

        .sidebar__nav-icon {
            width: 2.7rem;
            height: 2.7rem;
            margin-bottom: 0.6rem;
        }

        .sidebar__locale-select {
            margin: 0 0 1rem 0;
        }

        ::v-deep {
            .scroll-container_horizontal > .scroll-container__content {
                display: flex;
                flex-direction: column;
                justify-content: center;
            }
        }
    }

    @media screen and (min-width: 120em) {
        .sidebar {
            flex: 0 0 10rem;
            padding: 3rem 0;
        }

        .sidebar__nav-item {
            width: 8rem;
            height: 8rem;
        }

        .sidebar__nav-icon {
            width: 3.4rem;
            height: 3.4rem;
            margin-bottom: 0.8rem;
        }

        .sidebar__locale-select::v-deep {
            .select__value {
                width: 4.8rem;
                height: 4.8rem;
            }

            .select__option {
                height: 4rem;
            }
        }

        .sidebar__logout {
            width: 4.8rem;
            height: 4.8rem;

            svg {
                width: 2.6rem;
            }
        }
    }
</style>
