<template>
    <svg viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg">
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M19.8092 3.3335H28.208C32.4074 3.3335 35.8323 6.66683 35.8323 10.7335V29.2668C35.8323 33.3502 32.4074 36.6668 28.1736 36.6668H19.792C15.5925 36.6668 12.1504 33.3502 12.1504 29.2835V21.2835H22.821L20.0673 23.9502C19.551 24.4502 19.551 25.2668 20.0673 25.7668C20.3255 26.0168 20.6697 26.1335 21.0139 26.1335C21.3409 26.1335 21.6851 26.0168 21.9433 25.7668L26.9688 20.9168C27.227 20.6835 27.3647 20.3502 27.3647 20.0002C27.3647 19.6668 27.227 19.3335 26.9688 19.1002L21.9433 14.2502C21.427 13.7502 20.5836 13.7502 20.0673 14.2502C19.551 14.7502 19.551 15.5668 20.0673 16.0668L22.821 18.7168H12.1504V10.7502C12.1504 6.66683 15.5925 3.3335 19.8092 3.3335ZM4.16602 19.9999C4.16602 19.2999 4.75806 18.7166 5.46852 18.7166H12.1502V21.2832H5.46852C4.75806 21.2832 4.16602 20.7166 4.16602 19.9999Z"
        />
    </svg>
</template>

<script>
    export default {
        name: 'LogoutIcon',
    };
</script>
