<template>
    <svg viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M15.5268 2.98921L7.73513 5.90754C6.24763 6.47421 5.0293 8.23087 5.0293 9.83171V21.3067C5.0293 22.4542 5.78013 23.97 6.70096 24.65L14.4926 30.4725C15.8668 31.5067 18.1193 31.5067 19.4935 30.4725L27.2851 24.65C28.206 23.9559 28.9568 22.4542 28.9568 21.3067V9.83171C28.9568 8.24504 27.7385 6.47421 26.251 5.92171L18.4593 3.00337C17.666 2.69171 16.3343 2.69171 15.5268 2.98921Z"
            :fill="`url(#${gradientId})`"
        />

        <path
            d="M20.5418 14.8752C20.5418 12.9202 18.9552 11.3335 17.0002 11.3335C15.0452 11.3335 13.4585 12.9202 13.4585 14.8752C13.4585 16.4618 14.5068 17.7793 15.9377 18.2327V21.9585C15.9377 22.5393 16.4193 23.021 17.0002 23.021C17.581 23.021 18.0627 22.5393 18.0627 21.9585V18.2327C19.4935 17.7793 20.5418 16.4618 20.5418 14.8752Z"
            fill="#19202f"
        />

        <defs>
            <linearGradient :id="gradientId" x1="16.993" y1="2.76782" x2="16.993" y2="31.2482" gradientUnits="userSpaceOnUse">
                <stop offset="0" stop-color="#27334f" />
                <stop offset="1" stop-color="#6d7c9e" />
            </linearGradient>
        </defs>
    </svg>
</template>

<script>
    import navIconMixin from '@/mixins/navIconMixin';

    export default {
        name: 'AccountIcon',
        mixins: [navIconMixin],
    };
</script>
