<template>
    <svg viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M30.6849 14.79L29.2966 20.7116C28.1066 25.8258 25.7549 27.8941 21.3349 27.4691C20.6266 27.4125 19.8616 27.285 19.0399 27.0866L16.6599 26.52C10.7524 25.1175 8.92493 22.1991 10.3133 16.2775L11.7016 10.3416C11.9849 9.13747 12.3249 8.08914 12.7499 7.22497C14.4074 3.79664 17.2266 2.8758 21.9583 3.99497L24.3241 4.54747C30.2599 5.9358 32.0733 8.8683 30.6849 14.79Z"
            :fill="`url(#${gradientId})`"
        />

        <path
            d="M21.335 27.469C20.4567 28.064 19.3517 28.5599 18.0058 28.999L15.7675 29.7357C10.1433 31.549 7.18249 30.0332 5.355 24.409L3.54166 18.8132C1.72833 13.189 3.22999 10.214 8.85416 8.40069L11.0925 7.66402C11.6733 7.47985 12.2258 7.32402 12.75 7.22485C12.325 8.08902 11.985 9.13735 11.7017 10.3415L10.3133 16.2774C8.92499 22.199 10.7525 25.1174 16.66 26.5199L19.04 27.0865C19.8617 27.2849 20.6267 27.4124 21.335 27.469Z"
            fill="#7382a5"
        />

        <path
            d="M24.7774 14.8891C24.6924 14.8891 24.6074 14.8749 24.5083 14.8608L17.6374 13.1183C17.0708 12.9766 16.7308 12.3958 16.8724 11.8291C17.0141 11.2624 17.5949 10.9224 18.1616 11.0641L25.0324 12.8066C25.5991 12.9483 25.9391 13.5291 25.7974 14.0958C25.6841 14.5633 25.2449 14.8891 24.7774 14.8891Z"
            fill="#19202f"
        />

        <path
            d="M20.6268 19.6776C20.5418 19.6776 20.4568 19.6634 20.3576 19.6493L16.2351 18.6009C15.6684 18.4593 15.3284 17.8784 15.4701 17.3118C15.6118 16.7451 16.1926 16.4051 16.7593 16.5468L20.8818 17.5951C21.4484 17.7368 21.7884 18.3176 21.6468 18.8843C21.5334 19.3659 21.1084 19.6776 20.6268 19.6776Z"
            fill="#19202f"
        />

        <defs>
            <linearGradient :id="gradientId" x1="20.4998" y1="3.5437" x2="20.4998" y2="27.5231" gradientUnits="userSpaceOnUse">
                <stop stop-color="#27334f" />
                <stop offset="1" stop-color="#6d7c9e" />
            </linearGradient>
        </defs>
    </svg>
</template>

<script>
    import navIconMixin from '@/mixins/navIconMixin';

    export default {
        name: 'FaqIcon',
        mixins: [navIconMixin],
    };
</script>
